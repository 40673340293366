import React, { useEffect, useState } from 'react'
import * as S from './styles'
import ContractHook from '../../hooks/ContractHook';
import { convertAmountLunes, makeNumbersRandomBet, type_amount_lunes } from '../../utils/convert';
import { Box, CircularProgress, Modal } from '@mui/material';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
};
const BetPage = () => {
    const {
        error,
        contract,
        successMsg,
        loading,
        doPlayGame,
        setSuccessMsg,
        setError,
        infoLotto,
        getLottoInfo

    } = ContractHook()
    const [isAutomatic, setIsAutomatic] = useState(false)
    const [numberTicket, setNumberTicket] = useState([])
    const [listNumberTickets, setListNumberTickets] = useState([[]])
    const [buttonBet, setButtonBet] = useState([])
    const [buttonCountBet, setButtonCountBet] = useState([])
    const [buttonSelectBet, setButtonSelectBet] = useState([])
    const [numRandom, setNumRandom] = useState("01")
    const [betActive, setBetActive] = useState<any>()
    const [txLunes, setTxLunes] = useState(0)
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        ComponentBetButton()
        componentCountBet()
        NumSelected()
    }, [])
    useEffect(() => {
        getLottoInfo()
    }, [contract])
    useEffect(() => {
        ComponentBetButton()
        NumSelected()
    }, [numberTicket])
    useEffect(() => {
        if (infoLotto) {
            let total = convertAmountLunes(betActive.price) * listNumberTickets.filter(el => el.length != 0).length
            let total_tx = (total * 17) / 100
            setTxLunes(total_tx)
        }

    }, [listNumberTickets, numberTicket])
    useEffect(() => {
        componentCountBet()
    }, [numRandom])
    useEffect(() => {
        console.log("infoLotto",infoLotto)
        if (infoLotto) 
            setBetActive(infoLotto)
    }, [infoLotto])
    useEffect(() => {
        if (error || successMsg) {
            setIsOpen(true)
            if (successMsg) {
                setListNumberTickets([])
                setNumberTicket([])
            }
        }
    }, [error, successMsg])
    const closeMessage = () => {
        setIsOpen(false)
        setSuccessMsg("")
        setError("")
    }
    const handleSelectNumber = (num: number) => {

        let boll = num.toString().padStart(2, '0');
        const tempProperties: any = [...numberTicket];
        let numFind = tempProperties.find(el => el == boll)
        if (numFind) {
            const fill = tempProperties.filter(el => el != boll)
            setNumberTicket(fill)
        } else {
            if (numberTicket.length >= 6)
                return;
            tempProperties.push(boll)
            setNumberTicket(tempProperties)
        }

    }
    const ComponentBetButton = () => {
        let buttons: any = []
        for (let index = 0; index < 60; index++) {
            let boll = (index + 1).toString().padStart(2, '0')
            let bollSelect = numberTicket.find(el => el == boll)
            buttons.push(<S.ButtonBet key={index}
                color={bollSelect ? "#37C474" : null}
                onClick={() => handleSelectNumber(index + 1)}
                colorTextBtn={bollSelect ? "#fff" : null}
            >{boll}</S.ButtonBet>)

        }
        setButtonBet(buttons)
    }
    const componentCountBet = () => {
        let buttons: any = []
        for (let index = 0; index < 10; index++) {
            let boll = (index + 1).toString().padStart(2, '0')
            let bollSelect = numRandom == boll;
            buttons.push(<S.ButtonBet key={index}
                color={bollSelect ? "#37C474" : null}
                colorTextBtn={bollSelect ? "#fff" : null}
                onClick={() => setNumRandom(boll)}>{boll}</S.ButtonBet>)

        }
        setButtonCountBet(buttons)
    }
    const handleAddTicket = () => {
        if (isAutomatic) {
            let numbers: any = [...listNumberTickets]
            for (let index = 0; index < Number(numRandom); index++) {
                let num: any = makeNumbersRandomBet()
                if (num)
                    numbers.push(num)
            }
            setListNumberTickets(numbers)
        } else
            if (numberTicket.length == 6) {
                setListNumberTickets([...listNumberTickets, numberTicket])
                setNumberTicket([])
            }

    }
    const NumSelected = () => {
        let buttons: any = []
        for (let index = 0; index < 6; index++) {

            let boll = numberTicket[index];

            buttons.push(<S.NumSelected>{boll ? boll : ""}</S.NumSelected>)

        }
        setButtonSelectBet(buttons)
    }
    const BetSelected = (number: string) => {

        return <S.NumSelected key={number} width={"24px"} Height={"24px"} size={"16px"}>{number}</S.NumSelected>
    }
    const ComponentBollBetTicket = () => {
        return (
            <>
                {listNumberTickets.filter(el_ => el_.length != 0).map((el, index) => (
                    <S.CardBodyContainer key={index}>
                        <S.Title size="18px" width={"10%"}>#{index + 1}</S.Title>
                        {
                            el.map((el_: string) => BetSelected(el_))
                        }
                        <S.Title color={"#fff"} size="18px" width={"20%"}>{type_amount_lunes(convertAmountLunes(betActive.price))}</S.Title>
                        <img src='img/trash.svg' style={{ float: "right" }} onClick={() => handleRemoveTicket(index)} />
                    </S.CardBodyContainer>
                ))}

            </>
        )
    }
    const handleRemoveTicket = (index: number) => {
        let newTickets = listNumberTickets.filter((el_, index_) => index_ != index && el_.length != 0)
        setListNumberTickets(newTickets)
    }
    const handleDoPlayGame = () => {
        if(loading){
            return;
        }
        let listTicket: any = []
        for (let index = 0; index < listNumberTickets.length; index++) {
            const element = listNumberTickets[index];
            if (element.length) {
                const numTicket = {
                    num1: element[0],
                    num2: element[1],
                    num3: element[2],
                    num4: element[3],
                    num5: element[4],
                    num6: element[5]
                }
                listTicket.push(numTicket)
            }
        }
        if (listTicket.length == 0)
            return;
        let amount = Math.round(((convertAmountLunes(betActive.price) * listTicket.length) + txLunes) * 100000000)

        doPlayGame(listTicket, amount.toString(), false)
    }
    const ModalMessageBet = () => {
        return (
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(!isOpen)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <S.ContentDetails>
                        {successMsg ? (
                            <S.ContainerSuccess>
                                <h1>Success!</h1>
                                <p>{successMsg}</p>
                                <S.ButtonRadius color={"#fff"}  backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => closeMessage()}>OK</S.ButtonRadius>
                                <S.ButtonRadius color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={""} onClick={() => { location.href = "/myBets" }}>View bet</S.ButtonRadius>
                            </S.ContainerSuccess>
                        ) : (
                            <S.ContainerError>
                                <h1>Attention!</h1>
                                <p>{error}</p>
                                <S.ButtonRadius color={"#fff"}  backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => closeMessage()}>OK</S.ButtonRadius>
                            </S.ContainerError>
                        )}
                    </S.ContentDetails>
                </Box>
            </Modal>
        )
    }
    if (!betActive)
        return <>
            <S.Container>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </S.Container>
        </>
    return <>
        <S.Container>
            <S.CardContainer>
                <S.Title key={1}>Lotto Lunes - Sorteio {betActive.raffleId.toString().padStart(2, '0')}</S.Title>
                <S.CardBetContainer>
                    <S.CardBetBodyContainer>
                        <S.ButtonContainer onClick={() => setIsAutomatic(!isAutomatic)}>
                            {isAutomatic ? (
                                <>
                                    <S.ButtonDisableContainer>Manual</S.ButtonDisableContainer>
                                    <S.ButtonActiveContainer>Automatic</S.ButtonActiveContainer>
                                </>
                            ) : (
                                <>
                                    <S.ButtonActiveContainer>Manual</S.ButtonActiveContainer>
                                    <S.ButtonDisableContainer>Automatic</S.ButtonDisableContainer>
                                </>
                            )}

                        </S.ButtonContainer>
                        {isAutomatic ? (
                            <>
                                <S.Title key={2} color="#fff" size="18px" position="start" padding="10px 0px"> <img src='img/ticket-fff.svg' /> Select the Number of Tickets</S.Title>
                                <S.CardBodyContainer>
                                    {
                                        buttonCountBet.map(el => el)
                                    }
                                </S.CardBodyContainer>
                            </>
                        ) : (
                            <>
                                <S.Title key={3} color="#fff" size="18px" position="start" padding="10px 0px"> Enter the numbers of your bet</S.Title>
                                <S.CardBodyContainer>
                                    {
                                        buttonBet.map(el => el)
                                    }
                                    {
                                        buttonSelectBet.map(el => el)
                                    }
                                </S.CardBodyContainer>
                            </>
                        )}


                        <S.ContainerButton>
                            <S.ButtonRadius onClick={() => handleAddTicket()} color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} size={"300px"} >Create</S.ButtonRadius>
                        </S.ContainerButton>
                        <S.Title color="#fff" size="18px" position="start" padding="10px 0px"> <img src='img/ticket-fff.svg' /> # Numbers of your bet</S.Title>
                        {ComponentBollBetTicket()}
                    </S.CardBetBodyContainer>
                    <S.CardBetBodyContainer>
                        <S.Title color="#fff" size="18px">Checkout</S.Title>
                        <S.CardBodyContainer>
                            <S.Title size="18px">Confirm Bet</S.Title>
                            <S.CardInfoContainer>Fee charged for the service <span>{txLunes} Lunes</span></S.CardInfoContainer>
                            <S.HrBet />
                            <S.CardInfoContainer>Bet details</S.CardInfoContainer>
                            <S.CardInfoContainer>Name of the Bet <span>Lotto Lunes - sorteio {betActive.raffleId.toString().padStart(2, '0')}</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Quantity of Tickets <span>{listNumberTickets.filter(el => el.length != 0).length.toString().padStart(2, '0')}</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Ticket Value <span>{(convertAmountLunes(betActive.price))} Lunes</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Total Value <span>{(convertAmountLunes(betActive.price) * listNumberTickets.filter(el => el.length != 0).length) + txLunes} Lunes</span></S.CardInfoContainer>
                        </S.CardBodyContainer>
                        {betActive.status?(
                            <S.ContainerButton>
                            <S.ButtonRadius
                                color={"#fff"}
                                backcolor={'transparent'}
                                borderadius={"12px"}
                                bordercolor={"1px solid #37C474"}
                                onClick={() => handleDoPlayGame()}
                                disable={!loading}
                                size={"500px"} >
                                {loading ? (
                                    <CircularProgress />
                                ) : <></>}
                                Pay Ticket {(convertAmountLunes(betActive.price) * listNumberTickets.filter(el => el.length != 0).length) + txLunes} LUNES</S.ButtonRadius>
                        </S.ContainerButton>
                        ):<></>}
                        
                        {/**
                        *  <S.ContainerButton>
                            <S.ButtonRadius color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} size={"500px"} >Pay Lunes Fee 5</S.ButtonRadius>
                        </S.ContainerButton>
                        */}
                        <S.ContainerInfoCommunity>
                            <S.Title size="18px">Don't have the Lunes? Then can you buy here!</S.Title>
                        </S.ContainerInfoCommunity>
                        <S.ContainerButton onClick={()=> window.open('https://otc.lunes.io/', '_blank', 'noopener')}>
                            <S.ButtonRadius size="100%" color={"#fff"} backcolor={'#292A33'} borderadius={"10px"}>Lunes OTC <img src='img/lunesico.svg' /></S.ButtonRadius>
                        </S.ContainerButton>
                        <S.CardBodyContainer>
                            <S.Title size="18px" position="left">Get to know the Lunes community</S.Title>
                            <S.Title size="16px" color={"#fff"} position="left">And learn why we chose the Lunes Blockchain as our infrastructure to develop dApps securely and magically.</S.Title>
                            <S.ContainerLink onClick={()=>window.open('https://t.me/LunesGlobal', '_blank', 'noopener')}>
                                Learn more
                                <img src='img/arrow-right.svg' style={{ float: "right" }} />
                            </S.ContainerLink>
                        </S.CardBodyContainer>
                    </S.CardBetBodyContainer>
                </S.CardBetContainer>
                {ModalMessageBet()}
            </S.CardContainer>
        </S.Container>
    </>
}
export default BetPage