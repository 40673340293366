import React from 'react';
import { ApiContextProvider } from './context/ApiContext';
import HomePage from './page/home';
import GlobalStyles from './styles/globals'
import Header from './containers/header';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import FooterComponent from './containers/footer/FooterComponent';
import BetPage from './page/bet';
import MyBetPage from './page/mybet';

function is_mobile() { 
	if( navigator.userAgent.match(/Android/i)
	|| navigator.userAgent.match(/webOS/i)
	|| navigator.userAgent.match(/iPhone/i)
	|| navigator.userAgent.match(/iPad/i)
	|| navigator.userAgent.match(/iPod/i)
	|| navigator.userAgent.match(/BlackBerry/i)
	|| navigator.userAgent.match(/Windows Phone/i)
	){
	   return true;
	 }
	else {
	   return false;
	 }
}
const App = () => {
	if (is_mobile())
		return <>
		<div style={{
			color:"#fff",			
			justifyContent:"center",
			justifyItems:"center",
			textAlign:"center",
			alignItems:"center",
			margin:"auto",

		}}>
			{"Sorry don't support in mobile yet!!"}
		</div>
			
		</>
	const getBack = () =>{
		return location.pathname != "/"?true:false
	}
	const getIfBet= () =>{
		return location.pathname == "/myBets"?true:false
	}
	return (
		<ApiContextProvider>
			<>
				<Header  back={getBack()} myBet={getIfBet()}/>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="home" element={<HomePage />} />
						<Route path="bet" element={<BetPage />} />
						<Route path="myBets" element={<MyBetPage />} />						
					</Routes>
				</BrowserRouter>
				<FooterComponent/>
				<GlobalStyles />
			</>
		</ApiContextProvider>
	);
};

export default App;
