
import { useContext, useEffect, useState } from "react"
import { calc_fee, convertAmountLunes, type_amount_lunes } from "../utils/convert"
import { ApiContext } from '../context/ApiContext'
import {
  web3Enable,
  web3Accounts,
  web3FromSource,
} from '@polkadot/extension-dapp'
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'
import { ApiPromise, Keyring } from '@polkadot/api'
import { ContractPromise } from '@polkadot/api-contract'
import ABI from '../artifacts/lotto_lunes.json'
import { BN } from '@polkadot/util/bn'
import { getLottoLunesAPI, getMyLottosAP1, getMyPlayersAP1, saveAddress, savePlayers, updatePayment } from "../services/lunesServices"
const decimals = new BN('100000000')
const SEED_AUX: string = process.env.REACT_APP_SEED || 'bottom drive obey lake curtain smoke basket hold race lonely fit walk//Alice'
const CONTRACT_ADDRESS: string = process.env.REACT_APP_CONTRACT_ADDRESS || '5G3VfP36indbUdwLqPggkdbyfJfZg5ZpKyiT3HuD4Wg6tyzG'
// Create a keyring instance
const keyring = new Keyring({ type: 'sr25519' });

const ContractHook = () => {
  const { api, apiReady } = useContext(ApiContext)
  const [error, setError] = useState('')
  const [successMsg, setSuccessMsg] = useState('')
  const [contract, setContract] = useState<ContractPromise>()
  const [account, setAccount] = useState<InjectedAccountWithMeta>()
  const [loading, setLoading] = useState(true)

  const [balanceLunes, setBalanceLunes] = useState<string>("")
  const [feeNetWork, setFeeNetWord] = useState<number>(0)
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([])
  const [allRaffle, setAllRaffle] = useState<any>()
  const [allRaffleActive, setAllRaffleActive] = useState([])
  const [infoLotto, setInfoLotto] = useState<any>()
  const getGasLimit = (api: ApiPromise) =>
    api.registry.createType(
      'WeightV2',
      api.consts.system.blockWeights['maxBlock']
    )
  useEffect(() => {
    connectContract()
    connectWalletHandler()
  }, [api, apiReady, account])
  useEffect(() => {
    getBalance()
  }, [api, apiReady, account])
  const getBalance = async () => {
    if (!api || !apiReady || !account) return
    const balanceL: any = await api.query.system.account(account?.address)
    const balance_free = convertAmountLunes(balanceL.data.free.toHuman()) - convertAmountLunes(balanceL.data.feeFrozen.toHuman())
    console.log("balanceLunes",balance_free.toString())
    setBalanceLunes(balance_free.toString())
    setLoading(false)
  }
  const connectContract = () => {
    if (!api || !apiReady) return
    const contract = new ContractPromise(api, ABI, CONTRACT_ADDRESS);
    setContract(contract)
  }
  const connectWalletHandler = async () => {
    if (account)
      return;
    setLoading(true)
    setError('')
    if (!api || !apiReady) {
      return
    }
    const extensions = await web3Enable('Lotto Lunes')
    /* check if wallet is installed */
    if (extensions.length === 0) {
      setError('The user does not have any wallet installed')
      setLoading(false)
      return
    }
    // set the first wallet as the signer (we assume there is only one wallet)
    api.setSigner(extensions[0].signer)
    const injectedAccounts = await web3Accounts()
    if (injectedAccounts.length > 0) {
      setAccounts(injectedAccounts)
      let accountId = sessionStorage.getItem("accountId")
      if (accountId) {
        let account = injectedAccounts.find(el => el.address == accountId)
        if (account)
          setAccount(account)
        else
          setAccount(injectedAccounts[0])
      } else
        setAccount(injectedAccounts[0])
    }
  }
  const handleOnSelect = async (event: any) => {
    if (!api || !apiReady) {
      setError('The API is not ready')
      return false
    }
    const address: string = event.target.value
    const account = accounts.find((account: any) => account.address === address)
    if (account) {
      setAccount(account)
      const injected = await web3FromSource(account.meta.source)
      api.setSigner(injected.signer)      
    }
  }

  const getAccountAux = () => keyring.addFromUri(SEED_AUX);

  
  const getAllLottoPage = async (page: string = "0", done: boolean = false) => {
    if (!api || !apiReady) {
      return
    }
    if (!contract) {
      return
    }
    let address = getAccountAux().address;
    let payload = await getLottoLunesAPI(address, page,done?1:0)
    console.log(payload)
    setAllRaffle(payload)    
  }
  const getLotto = async (page: string = "1", id: string = "0") => {
    if (!api || !apiReady) {
      return
    }
    if (!contract) {
      return
    }
    
    let address = getAccountAux().address;
    const gasLimit: any = getGasLimit(api);
    const { result, output }: any = await contract.query['lottoLunesImpl::allRaffle'](
      address,

      {
        gasLimit,
      },
      id,
      page
    )
    if (result.isErr) {
      setError(result.isErr);
    }
    if (output && !result.isErr) {
      const object = output.toHuman().Ok?.Ok;
      console.log(object)
      return object
    }
    return []
  }

  const getLottoInfo = async () => {
    if (!api || !apiReady) {
      return
    }
    if (!contract) {
      return
    }
    if(account)
      saveAddress(account.address)
    
    let address = getAccountAux().address;
    const gasLimit: any = getGasLimit(api);
    const { result, output }: any = await contract.query['lottoLunesImpl::infoContract'](
      address,

      {
        gasLimit,
      }
    )
    if (result.isErr) {
      setError(result.isErr);
    }
    if (output && !result.isErr) {
      const object = output.toHuman().Ok?.Ok;
      console.log("infoContract",object)
      setInfoLotto(object)
      return object
    }
    return []
  }
  const getMyGameStatus = async (page: string = "0", done: boolean =false) => {
    if (!api || !apiReady) {
      return
    }
    if (!contract) {
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }
    try {
      setLoading(true)
      if (!api || !apiReady) {
        return
      }
      if (!contract) {
        return
      }
      let payload = await getMyLottosAP1(account.address, page,done?1:0)
      console.log("getMyLottosAP1",payload)
      setAllRaffle(payload)
      setLoading(false)
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
    
  }
  const doPlayGame = async (numRifle: any, amount: string, isFee: boolean = true) => {
    if (!api || !apiReady) {
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }
    setLoading(true)
    try {
      console.log(amount)
      let address = account.address;
      const gasLimit: any = getGasLimit(api);
      const { storageDeposit, result, gasRequired }: any = await contract.query['lottoLunesImpl::playLunes'](
        address,
        {
          gasLimit,
          storageDepositLimit: null,
          value: amount
        },
        numRifle
      )
      if (result.isErr) {
        setLoading(false)
        let error = ""
        setError("");
        if (result.asErr.isModule) {
          const dispatchError = api.registry.findMetaError(result.asErr.asModule)
          error = dispatchError.docs.length ? dispatchError.docs.concat().toString() : dispatchError.name
        } else {
          error = result.asErr.toString()
        }
        setError(error)
        return
      }
      if (result.isOk) {
        const flags = result.asOk.flags.toHuman()
        if (flags.includes('Revert')) {
          setLoading(false)
          setError("");
          console.log('Revert')
          console.log(result.toHuman())
          const type = contract.abi.messages[5].returnType
          const typeName = type?.lookupName || type?.type || ''
          const error = contract.abi.registry.createTypeUnsafe(typeName, [result.asOk.data]).toHuman()
          setError(error ? (error as any).Err : 'Revert')
          return
        }
      }
  
      let fee_ = calc_fee(storageDeposit.toHuman().Charge)
      setFeeNetWord(fee_)
      if (isFee) {
        setLoading(false)
        return;
      }

      await contract.tx['lottoLunesImpl::playLunes']({
        gasLimit: gasRequired,
        storageDepositLimit: null,
        value: amount
      },
        numRifle)
        .signAndSend(address, async (res) => {
          if (res.status.isInBlock) {
            console.log('in a block')
          }
          if (res.status.isFinalized) {
            let data_:any= [];
            for (let index = 0; index < numRifle.length; index++) {
              const element = numRifle[index];
              let objet = {
                num1:element.num1,
                num2 :element.num2,
                num3 :element.num3,
                num4 :element.num4,
                num5 :element.num5,
                num6 :element.num6,
                hits:0,
                isPayment:0,
                txId:res.txHash.toHuman(),
                status:0,
                valueAward:"0",
                lottoDrawsId:0,
                lottoUserId:account.address
              }
              data_.push(objet)
            }
            await savePlayers(account.address,data_)
            setError("")
            setSuccessMsg("")
            setSuccessMsg('Bet placed successfully!!')
            setLoading(false)
          }
        })
    } catch (error: any) {
      setError("Erro Trasaction")
      console.log(error)
      setLoading(false)
    }

  }

  const doPaymentGame = async (id: string, isFee: boolean = true) => {
    if (!api || !apiReady) {
      return
    }
    if (!account) {
      setError('Account not initialized')
      return
    }

    if (!contract) {
      setError('Contract not initialized')
      return
    }
    try {
      setLoading(true)
      let address = account.address;
      const gasLimit: any = getGasLimit(api);
      const { storageDeposit, result, gasRequired }: any = await contract.query['lottoLunesImpl::payment'](
        address,

        {
          gasLimit,
          storageDepositLimit: null,
        },
        id
      )
      console.log("storageDeposit",storageDeposit.toHuman())
      console.log("result",result.toHuman())
      console.log("gasRequired",gasRequired.toHuman())
      if (result.isErr) {
        setLoading(false)
        let error = ""
        setError("");
        if (result.asErr.isModule) {
          const dispatchError = api.registry.findMetaError(result.asErr.asModule)
          error = dispatchError.docs.length ? dispatchError.docs.concat().toString() : dispatchError.name
        } else {
          error = result.asErr.toString()
        }
        setError(error)
        return
      }
      if (result.isOk) {
        const flags = result.asOk.flags.toHuman()
        if (flags.includes('Revert')) {
          setLoading(false)
          setError("");          
          setError("You are not the winner :(")
          return
        }
      }
  
      let fee_ = calc_fee(storageDeposit.toHuman().Charge)
      setFeeNetWord(fee_)
      if (isFee)
        return;
      await contract.tx['lottoLunesImpl::payment']({
        gasLimit: gasRequired,
        storageDepositLimit: null,
      },
        id)
        .signAndSend(account.address, async (res) => {
          if (res.status.isInBlock) {
            console.log('in a block')
          }
          if (res.status.isFinalized) {     
            await updatePayment(account.address,id,res.txHash.toHuman()?.toString())      
            setError("")
            setSuccessMsg("")
            setSuccessMsg('You have won a prize!')
            setLoading(false)
          }
        })
    } catch (error) {
      setError("Erro Trasaction")
      console.log(error)
      setLoading(false)
    }

  }

  return {
    contract,
    loading,
    apiReady,
    error,
    account,
    balanceLunes,
    feeNetWork,
    successMsg,
    setLoading,
    accounts,
    allRaffle,
    setSuccessMsg,
    setError,
    connectWalletHandler,
    handleOnSelect,
    doPlayGame,
    doPaymentGame,
    getLotto,
    getMyGameStatus,
    getAllLottoPage,
    getLottoInfo,
    infoLotto,
    allRaffleActive,
    setAllRaffleActive,
  }
}
export default ContractHook
