import React from 'react';
import * as S from './styles'
const AboutComponent = () => {
    return <>
        <S.CardContainer>
            <S.CardTitle>
                <S.Title>
                    Why bet on Lotto Lunes?
                </S.Title>
                <S.SubTitle>
                    For just $3 and a small fee of 5 Lunes, dive into the excitement of choosing six numbers and watching your number turn into victory. Lotto Lunes is your star lottery - where dreams become reality!
                </S.SubTitle>
            </S.CardTitle>
            <S.ContentBox>
                <S.BoxWithFoot>
                    <S.BoxWithIcon>
                        <S.IconBox>
                            <img src='img/lupa.svg'></img>
                        </S.IconBox>
                    </S.BoxWithIcon>
                    <S.DescTex><S.HighlightedText>Transparency:</S.HighlightedText> The immutable rules of smart contracts ensure a fair and transparent game, increasing the trust of participants.</S.DescTex>
                </S.BoxWithFoot>
                <S.BoxWithFoot>
                    <S.BoxWithIcon>
                        <S.IconBox>
                            <img src='img/engrenagens.svg'></img>
                        </S.IconBox>
                    </S.BoxWithIcon>
                    <S.DescTex><S.HighlightedText>Cost Efficiency:</S.HighlightedText> Cost Efficiency: Automation via smart contracts reduces operational costs, potentially leading to larger prizes.</S.DescTex>
                </S.BoxWithFoot>
                <S.BoxWithFoot>
                    <S.BoxWithIcon>
                        <S.IconBox>
                            <img src='img/globo.svg'></img>
                        </S.IconBox>
                    </S.BoxWithIcon>
                    <S.DescTex><S.HighlightedText>Broad Access:</S.HighlightedText> Without geographical or banking restrictions, a decentralized lottery is globally accessible, promoting financial inclusion.</S.DescTex>
                </S.BoxWithFoot>
            </S.ContentBox>
        </S.CardContainer>
    </>
}
export default AboutComponent;