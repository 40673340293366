import { format } from 'date-fns';
const calc_fee = (fee_deposit: string) => {
    if(!fee_deposit)
     return 0
    let fee_ = (fee_deposit.split(" ")[0]).replaceAll(".", "").trim()
    return Number(fee_) / 100000000
}
const type_amount_lunes = (value:number) =>{
    
    if(value<1000)
        return `${(value)}Uni`
    else if(value >=1000 && value<1000000)
        return `${value}k`
    else if(value >=1000000 && value<1000000000)
        return `${value}M`
    else
        return `${value}B`
}
const convertTimestamp = (value: string) => {   
    return value.replaceAll(",", "").toString()
}
const convertAmountLunes = (value: string) => {
    if(!value)
        return 0
    return Number(value.replaceAll(",", "").toString()) / 100000000
}

const truncateText = (str:string, qtd:number, qtd_tru:number, isEnd:boolean = false) =>{
    if (isEnd)
        return str.length > qtd ? str.substring(0, qtd_tru) + "..." : str;
    else
        return str.length > qtd ? str.substring(0, qtd_tru) + "..."+str.substring((str.length - qtd_tru), str.length) : str;
}
const  makeNumbersRandomBet = () => {
    const numbers:string[] = [];
    while (numbers.length < 6) {
        // Gerar um número aleatório entre 1 e 60
        const number = (Math.floor(Math.random() * 60) + 1).toString().padStart(2,'0');
        // Verificar se o número já foi sorteado antes
        if (!numbers.includes(number)) {
            numbers.push(number);
            
        }
    }
    numbers.sort((a, b) => Number(a) - Number(b))
    return numbers;
}
const getDateView = (dateTime:string, format_: string) =>{
    let dateRaffle = new Date(dateTime)
   // dateRaffle.setTime(Number(dateTime.replaceAll(",", "").toString()))
    return format(dateRaffle, format_)

}
export {calc_fee, type_amount_lunes, convertTimestamp,convertAmountLunes, truncateText, makeNumbersRandomBet,getDateView}