import axios from 'axios';

export const getApi  = (accountId:string) => {
    const sign = require('jwt-encode');
    const secret = process.env.REACT_APP_SECRET;
    const data = {
      sub: '1234567890',
      data: accountId,
      iat: 1516239022,
      expiresIn: 1000
    };
    const jwt = sign(data, secret);
    let CryptoJS = require("crypto-js");
    
    let auth = CryptoJS.AES.encrypt(JSON.stringify("Basic "+jwt), process.env.REACT_APP_SECRET)
    return axios.create({
        baseURL: process.env.REACT_APP_URL,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            "Authorization":auth
        }
    })
}