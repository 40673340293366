import React, { useEffect, useState } from 'react'
import * as S from './styles'
import ContractHook from '../../hooks/ContractHook';
import { Box, Chip, CircularProgress, List, Modal, Paper } from '@mui/material';
import { convertAmountLunes, convertTimestamp, truncateText } from '../../utils/convert';
import { format } from 'date-fns';
import StarBorderOutlined from '@mui/icons-material/StarBorderOutlined';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border:"1px solid #2d975b",
    borderRadius:"12px"

};
const MyBetPage = () => {
    const {
        allRaffle,
        error,
        contract,
        successMsg,
        loading,
        account,
        doPaymentGame,
        setSuccessMsg,
        setError,
        getMyGameStatus,
    } = ContractHook()
    const [all, setAllRaffle] = useState([])
    const [betWin, setBetWin] = useState([])
    const [isOpen, setIsOpen] = useState(false)
    const [viewBet, setViewBet] = useState<any>()
    const [isOpenMessage, setIsOpenMessage] = useState(false)
    const [newAddress, setNewAddress] = useState(sessionStorage.getItem("accountId"));
    const [typeActive, setTypeActive] = useState(false)
    const [page, setPage] = useState(0)

    useEffect(() => {
        if (account)
            getMyGameStatus()
    }, [contract])

    useEffect(() => {
        GetAppPage()
        if (account?.address) {
            setInterval(function () {
                let acc = sessionStorage.getItem("accountId")
                if (acc && acc != account?.address)
                    setNewAddress(acc)

            }, 2000);
            if (newAddress) {
                if (account?.address != newAddress)
                    location.reload()
            }

        }
    }, [account, newAddress])
    useEffect(() => {
        setListRaffle()
    }, [allRaffle])
    useEffect(() => {
        if (error || successMsg) {
            setIsOpenMessage(true)
            if (successMsg) {
                GetAppPage()
            }
        }

    }, [error, successMsg])

    useEffect(() => {
        GetAppPage()
    }, [typeActive, page])
    const handleValidatedTicket = async (id:string) => {
        if(loading)
            return
        let ticketWin = viewBet.gameRaffle.filter((el: any) => el.hits >= 2);
        if (ticketWin.length > 0) {
            await doPaymentGame(id, false)

        } else {
            setError("You are not the winner :(")
        }

    }
    const closeMessage = () => {
        setIsOpenMessage(false)
        setSuccessMsg("")
        setError("")
    }
    const GetAppPage = () => {
        getMyGameStatus(page.toString(), typeActive)
    }
    const setListRaffle = async () => {
        let list_: any = [];
        let wins: any = [];
        if (!allRaffle)
            return;
        for (let index = 0; index < allRaffle?.data?.lottos?.length; index++) {
            const element: any = allRaffle?.data?.lottos[index];
            let listAssociate:any = element.lotto_player_user
            let total = 0
            let body = {
                id: element.idRiffle.toString().padStart(2, '0'),
                dateCreate: listAssociate[0].createdAt,
                hits: element.hits,
                ticketId: element.id,
                status: element.statusDone,
                gameRaffle: listAssociate,
                valueAward: total,
                owner: listAssociate[0].lottoUserId,
                lotto: element.lotto_draws, 
                isPayment: listAssociate[0].isPayment==1,  
                price:element.price,
                dateDraw:element.dateDraw,
                lotto_numbers:   element.lotto_numbers,
                accumulated: element.accumulated,
                txPayment: "",
                isWin: false,
            }
            for (let w = 0; w < listAssociate.length; w++) {
                const elo:any = listAssociate[w];
                body.valueAward += Number(elo.valueAward)
                if (Number(elo.hits) >= 2 && elo.isPayment==0) {
                    wins.push(body)
                    body.isWin = true;
                }
                if(elo.txIdpayment)
                    body.txPayment = elo.txIdpayment
            }
            list_.push(body);
        }
        console.log("list_", list_)
        setBetWin(wins)
        setAllRaffle(list_)
    }
    const nextPage = () => {
        let page_ = Number(page)
        if (page_ != countTotalPage()) {
            page_ += 1;
            setPage(page_);
        }
    }
    const backPage = () => {
        let page_ = Number(page)
        if (page_ > 0) {
            page_ -= 1
            setPage(page_);
        }
    }
    const countTotalPage = () => {
        if (!allRaffle?.data?.lottos)
            return 0;
        let t = Math.ceil(Number(allRaffle?.data?._count) / 50)
        if (t == 0)
            t = 1
        return t
    }
    const BetSelected = (numbers: any, draws: any) => {
        let nUser = [
            Number(numbers.num1),
            Number(numbers.num2),
            Number(numbers.num3),
            Number(numbers.num4),
            Number(numbers.num5),
            Number(numbers.num6),
        ]
        let nDraw: any = []
        if (draws) {
            nDraw = [
                Number(draws.num1),
                Number(draws.num2),
                Number(draws.num3),
                Number(draws.num4),
                Number(draws.num5),
                Number(draws.num6),
            ]
           
        }
        nDraw.sort()
        nUser.sort(function(a, b){
            return a - b;
        });
        return nUser.map((el: any) => <S.NumSelected width={"24px"} backcolor={ nDraw.includes(el)? "#37C474" : null} Height={"24px"} size={"16px"}>{el.toString().padStart(2, '0')}</S.NumSelected>)

    }
    const ModalMessageBet = () => {
        return (
            <Modal
                open={isOpenMessage}
                onClose={() => closeMessage()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <S.ContentDetails>
                        {successMsg ? (
                            <S.ContainerSuccess>
                                <h1>Congratulations!</h1>
                                <p>{successMsg}</p>
                                <S.ButtonRadiusBet color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => closeMessage()}>OK</S.ButtonRadiusBet>
                            </S.ContainerSuccess>
                        ) : (
                            <S.ContainerError>
                                <h1>Try Again!</h1>
                                <p>{error}</p>
                                <S.ButtonRadiusBet color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => closeMessage()}>OK</S.ButtonRadiusBet>
                            </S.ContainerError>
                        )}
                    </S.ContentDetails>
                </Box>
            </Modal>
        )
    }
    const ModalDetailsBet = () => {
        return (
            <Modal
                open={isOpen}
                onClose={() => setIsOpen(!isOpen)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                <Paper style={{ maxHeight: 750, overflow: 'auto', backgroundColor: 'transparent', scrollbarWidth: 'thin',scrollbarColor:"black #37C474" }}>
                    {viewBet ? (
                        <S.ContentDetails>
                            <img src='img/ticket-fff.svg' />
                            <h1>Details ticket</h1>
                            <p>{format(viewBet.dateCreate, 'yyyy-MM-dd - HH:mm:ss')}</p>
                            <S.CardInfoContainer>Lotto <span>Lotto lunes - sorteio {viewBet.id.toString().padStart(2, '0')}</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Wallet <span>{truncateText(viewBet.owner, 15, 10)}</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Type <span>Lotto lunes</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Ticket Price <span> {convertAmountLunes(viewBet.price.toString())}</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Date <span>{format(viewBet.dateDraw, 'yyyy-MM-dd - HH:mm:ss')}</span></S.CardInfoContainer>
                            <S.CardInfoContainer>Quantity <span>{viewBet.gameRaffle.length}</span></S.CardInfoContainer>
                            {
                                viewBet?.valueAward && !viewBet?.txPayment?(
                                    <S.CardInfoContainer>Award Total <span>{convertAmountLunes(viewBet.valueAward.toString())} LUNES</span></S.CardInfoContainer>
                                ):<></>
                            }
                            {
                                viewBet?.txPayment?(
                                    <a href={`https://blockexplorer.lunes.io/tx/${viewBet.txPayment}`} target='_black'><S.CardInfoContainer isLink={true}>Award Total is Pay <span>{convertAmountLunes(viewBet.valueAward.toString())} LUNES</span></S.CardInfoContainer></a> 
                                ):<></>
                            }
                            {viewBet.status ? (<>
                                <S.TitleNumber> # Numbers drawn </S.TitleNumber>
                                <S.CardBodyContainer>
                                    {
                                        viewBet.lotto_numbers.map((el_: any) => BetSelected(el_, null))
                                    }
                                </S.CardBodyContainer>
                            </>
                            ) : (
                                <></>
                            )}
                            <S.TitleNumber> # Numbers of your bet</S.TitleNumber>
                            <Paper style={{ maxHeight: 250, overflow: 'auto', backgroundColor: 'transparent', scrollbarWidth: 'thin',scrollbarColor:"black #37C474"}}>
                                <List>
                                    {viewBet.gameRaffle.map((el, index) => (
                                        <S.CardBodyContainer key={index}>
                                            <S.Title size="18px" width={"10%"}>#{index + 1}</S.Title>
                                            {
                                                BetSelected(el, viewBet?.lotto_numbers[0])
                                            }
                                            {Number(el.hits) >= 2 ? (
                                                <>
                                                    <StarBorderOutlined color='success' />
                                                </>
                                            ) : <></>}
                                            {el.status ? (
                                                <S.Title size="18px" width={"10%"}><Chip label={el.hits} style={{ fontSize: 20 }} color="success" variant="outlined" /> Point</S.Title>
                                            ) : (
                                                <></>
                                            )}

                                        </S.CardBodyContainer>
                                    ))}
                                </List>
                            </Paper>

                            {viewBet.isWin ?(
                                <S.ButtonRadiusBet disable={!loading} color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => handleValidatedTicket(viewBet.id)}>
                                    {loading ? (
                                        <CircularProgress />
                                    ) : <></>}
                                    Withdraw your prizes here Now!
                                </S.ButtonRadiusBet>
                            ) : <></>}

                            <S.ButtonRadiusBet color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={""} onClick={() => setIsOpen(false)}>
                                OK
                            </S.ButtonRadiusBet>
                        </S.ContentDetails>
                    ) : (
                        <CircularProgress />
                    )}
                 </Paper>   
                </Box>
            </Modal>
        )
    }
    const setObjetBetView = (data: any) => {
        setViewBet(data)
        setIsOpen(true)
    }
    const ItemComponent = () => {
        if (all.length == 0)
            return <></>
        return all.map((el: any) => (
            <S.CardBodyTable bordercolor={el.isWin?"1px solid #37C474":(el.status==1?"1px solid #4242a8":null)}>
                <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>{format(el.dateCreate, 'yyyy-MM-dd')}</S.TextTable>
                <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>{convertAmountLunes(el.price)}</S.TextTable>
                <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>Lotto</S.TextTable>
                <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>{el.gameRaffle.length}</S.TextTable>
                <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>{convertAmountLunes(el.accumulated)} Lunes</S.TextTable>
                <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>
                    <S.ButtonRadius color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => setObjetBetView(el)}>
                        Details
                    </S.ButtonRadius>
                </S.TextTable>
            </S.CardBodyTable>
        ))
    }
    const Empty = () => {
        return <>
            <S.Container>
                <S.CardContainer>
                    <Box sx={{ display: 'flex' }}>
                        <S.ContentTitle>
                            <CircularProgress />
                            <S.TitleHome>Not found new bets yet</S.TitleHome>
                            <S.SubTitleHome>
                                Here you can view your bets, track your progress, and if you win, withdraw your prizes here!
                            </S.SubTitleHome>
                        </S.ContentTitle>
                    </Box>
                </S.CardContainer>
            </S.Container>
        </>
    }

    return <>
        <S.Container>
            <S.CardContainer>
                {betWin.length > 0 ? (
                    <S.ContentWinner>
                        <img src="img/ticket-star-white.svg" color='black' /> <p>There was a winner in the last draw, check if it's you, the prize is here!</p>
                        <S.ContentWinnerButton>
                            <S.ButtonRadius color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #fff"} onClick={() => setObjetBetView(betWin[0])}>
                                View Draw Result
                            </S.ButtonRadius>
                        </S.ContentWinnerButton>
                    </S.ContentWinner>
                ) : <></>}

                <S.ContentTitle>
                    <S.TitleHome>Draws Completed</S.TitleHome>
                    <S.SubTitleHome>
                        Here you can view your bets, track your progress, and if you win, withdraw your prizes here!
                    </S.SubTitleHome>
                </S.ContentTitle>
                <S.ContentDraws>
                    <S.TitleHome color={"#fff"} position={"start"}>Draws</S.TitleHome>
                    <S.ContentRow>
                        <S.ButtonRadius backcolor={!typeActive ? '' : '#292A33'} color={!typeActive ? '' : "#BCBCBC"} onClick={() => setTypeActive(false)}>All</S.ButtonRadius>
                        <S.ButtonRadius backcolor={typeActive ? '' : '#292A33'} color={typeActive ? '' : "#BCBCBC"} onClick={() => setTypeActive(true)}>Draws finished</S.ButtonRadius>
                    </S.ContentRow>
                    <S.CardHeaderTable>
                        <S.TextTable color={"#fff"} position={"start"}>Date</S.TextTable>
                        <S.TextTable color={"#fff"} position={"start"}>Ticket Value</S.TextTable>
                        <S.TextTable color={"#fff"} position={"start"}>Type of Bet</S.TextTable>
                        <S.TextTable color={"#fff"} position={"start"}>Tickets Total</S.TextTable>
                        <S.TextTable color={"#fff"} position={"start"}>Prize Value</S.TextTable>
                        <S.TextTable color={"#fff"} position={"start"}>Action</S.TextTable>
                    </S.CardHeaderTable>
                    {loading ? (
                        <CircularProgress />
                    ) : <></>}
                    {all.length != 0 ? ItemComponent() : (
                        Empty()
                    )}
                    <S.CardFooterTable>
                        <S.TextFooterTable color={"#fff"} position={"start"}>Total {allRaffle?.data?._count}  Page {page + 1} of {countTotalPage()}</S.TextFooterTable>
                        <S.ButtonFooterRadius backcolor={"#000"} onClick={() => backPage()}>
                            <img src="img/back.png" />
                        </S.ButtonFooterRadius>
                        <S.ButtonFooterRadius backcolor={"#000"} onClick={() => nextPage()}>
                            <img src="img/next.png" />
                        </S.ButtonFooterRadius>
                    </S.CardFooterTable>
                </S.ContentDraws>
            </S.CardContainer>
            {ModalDetailsBet()}
            {ModalMessageBet()}
        </S.Container>

    </>
}
export default MyBetPage