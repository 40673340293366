import React, { useEffect } from 'react'
import * as S from './styles'


import BannerComponent from '../../containers/banner/BannerComponent';
import AboutComponent from '../../containers/about/AboutComponent';
import FaqComponent from '../../containers/faq/FaqComponent';
import CalendarComponent from '../../containers/calendar/CalendarComponent';
import ContractHook from '../../hooks/ContractHook';


const HomePage = () => {
  const {
    getAllLottoPage,
    allRaffle,
    contract,    
    infoLotto,
    getLottoInfo,
  } = ContractHook()
  useEffect(()=>{
    getAllLottoPage()
    getLottoInfo()
  },[contract])
  const selectStatus = (page:string,done:boolean) =>{
    getAllLottoPage(page,done)
  }
  return (
    <>
      <S.Container>
        <BannerComponent data={infoLotto}/>
      </S.Container>
      <S.Container>
        <CalendarComponent data={allRaffle} getAll={selectStatus} />
      </S.Container>
      <AboutComponent />
      <FaqComponent />
    </>
  )
}

export default HomePage
