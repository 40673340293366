import React from 'react';
import * as S from './styles'
const FooterComponent = () => {
    return <>
      <S.CardContainer>
       <S.Title>
       Web3 developed by the world, for the world with lots of ❤
       </S.Title>
        <S.SubTitle>
        Our smart contract runs on the Lunes blockchain. <span>Contribute on our Github!</span>
        </S.SubTitle>
      </S.CardContainer>
    </>
  }
  export default FooterComponent;