import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'

type StyledProps = {
  bgHeader: string
  bgBtnHeader: string
  colorTextBtnHeader: string
  borderRadius: string
  justify:string
}

export const Header = styled.header<Partial<StyledProps>>`
  ${({ ...props }) => css`
    height: 90px;
    padding: 0 386px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${props.bgHeader || '#171821'};
    position: relative;
    z-index: 10;
    ${device.laptopL} {
      height: 90px;
      padding: 0;
    }
  `}
`

export const Nav = styled.nav`
  ${({ ...props }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `}
`

export const ContentLogo = styled.div`
  ${({ ...props }) => css`
    width: 100%;
    cursor: pointer;
    font-size: 20px;
    color: #37C474;
    img {
      height: 100%;
    }
    ${device.laptopL} {
      width: 300px;
      margin: 5px auto;
      img {
        height: 100%;
      }
    }
  `}
`

export const Button = styled.button<Partial<StyledProps>>`
${({ ...props }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 261px;
    height: 54px;
    padding: 16px 24px;
    border-radius: ${props.borderRadius}; 
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    flex-shrink: 0;
    background: ${props.bgBtnHeader || '#282826'};
    color: ${props.colorTextBtnHeader || '#fcc725'};

    ${device.laptopL} {
      width: 100px;
      font-size: 12px;
    }
  `}
`
export const ButtonMobile = styled(Button) <Partial<StyledProps>>`
  ${({ ...props }) => css`
    display: none;
    ${device.tablet} {
      width: 60px;
      display: flex;
    }
  `}
`
export const ContainerAccount =  styled.div`
  ${({ }) => css`    
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;  
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    padding: 10px;    
   
    ${device.tablet} {
      width: 60px;
      display: flex;
    }
    ${device.laptopL} {
      padding: 5px;    
      width: 230px;
      font-size: 12px;
    }
  `}
`
export const ContainerAccountBalance = styled(Button) <Partial<StyledProps>>`
  ${({ }) => css`   
    width:45%;
    display: flex;
    color:#000000;
    background:#37C474;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  `}
`
export const ContainerAccountAddress =  styled(Button) <Partial<StyledProps>>`
  ${({ }) => css`
    width:60%;
    display: flex;
    color:#fff;
    background: #292A33;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  `}
`
export const ContainerAccountButtonImg =  styled(Button) <Partial<StyledProps>>`
  ${({ }) => css`
    width: 150px;
    background: transparent;
    border-radius: 100%;  
    display: flex;
    justify-content: space-between;
    ${device.laptopL} {
      width: 100px;
      font-size: 12px;
    }
  `}
`
export const ContainerAccountImg =  styled.div`
  ${({ }) => css`
    width: 60px;
    height: 60px;
    img {
      border-radius: 50%;
      border: 2px solid #37C474;
    }
  `}
`
export const ContainerModalWallet =  styled.div`
  ${({ }) => css`
    width: 400px;
    height: auto;
    background-color:#292A33;
    padding: 10px;    
  `}
`
export const ContainerModalWalletTitle =  styled.div`
  ${({ }) => css`
    text-align:left;
    margin: 20px;
    justify-content: space-between;
    border-bottom: 0.3px solid #585860;
    h1{
      font-size: 20px;
      color: #37C474; 
      font-weight: bold;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin-bottom: 20px;
      margin-top: 10px;
    }
       
  `}
`
export const ContainerModalWalletBody =  styled.div`
  ${({ }) => css`
    justify-content: center;
    align-items: center;
    text-align:center;
    margin: 14px;
    h1{
      color: #37C474; 
      font-weight: bold;
      margin: 20px;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin: 30px;
    }
       
  `}
`
export const ContainerButton = styled.div`
  ${() => css`
    width: 100%;
    margin: 0px;
    padding: 5px;
    justify-content: space-around;
  `}
`
export const ButtonRadius = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    max-width:${props.size || '142px'};
    width: 100%;
    height: 52px;
    margin: auto;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;
    border-radius:${props.borderadius || '100px'};
    justify-content: ${props.justify || 'left'};;
    align-items: center;
    flex-shrink: 0;    
    background: ${props.backcolor || '#37C474'};
    color: ${props.color || '#000'};
    border:${props.bordercolor || '#37C474'};    
  `}
`
export const ContentDetails = styled.div`
  ${() => css`
    width: 500px;
    padding: 20px;
    text-align: left;
    margin: auto;
    background-color: #171821;
    border-radius: 12px;
    h1{
      margin-top: 10px;
      color: #37C474; 
      font-weight: bold;
      font-size: 20px;
    }
    p{
      color: #FFFFFF;
      font-size :18px ;
      margin-bottom: 20px;
    }
  `}
`
export const ContainerError = styled.div`
  ${() => css`
    padding: 10px;
    text-align: center;
    h1{
      color: #F8B304; 
      font-weight: bold;
      margin: 20px;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin: 30px;
    }
    justify-content: space-around;
  `}
`
export const ButtonRadiusBet = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width: 100%;
    height: 52px;
    margin: 14px 5px 16px;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;    
    border-radius:${props.borderadius || '100px'};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.backcolor || '#37C474'};
    color: ${props.color || '#000'};
    border:${props.bordercolor || ''};
  `}
`