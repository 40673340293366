import React, { useEffect, useState } from 'react'
import * as S from './styles'
import CardDownUp from './CardDownUp'
import questions from './questions.json' 
const FaqComponent = () => {
  const [list, setList] = useState([])
  useEffect(()=>{
    setList(questions as [])
  },[])
  return <>
    <S.CardContainer>
      <S.Title fontSize={'large'}>
        Have Questions?
      </S.Title>
      <S.ContentBox>
        <S.ContentFaq>
          <h1>FAQ</h1>
          {list.map((el:any)=><CardDownUp Answer={el.Answer} Quest={el.Quest} key={el.Id} />)}          
        </S.ContentFaq>
        <S.Img>
          <img src='img/bootLunes.svg' width={350} height={570}/>
        </S.Img>
        
      </S.ContentBox>
    </S.CardContainer>
  </>
}
export default FaqComponent;