import React, { useState } from 'react';
import * as S from './styles'
type QuestProps = {
    Answer: string
    Quest: string
  }
const CardDownUp = ( props:QuestProps) => {
    const [isOpen, setOpen] = useState(false);
    return <>
      <S.ContentFaqQuestion>
        <div onClick={()=>setOpen(!isOpen)}>
        <S.TitleBox>
        <h1>{props.Quest}</h1> {isOpen?<img src='img/down.svg' />:<img src='img/up.svg'/>}        
       </S.TitleBox>
       {isOpen?<p>{props.Answer}</p>:<></>}
        </div>
       
      </S.ContentFaqQuestion>
    </>
  }
  export default CardDownUp;