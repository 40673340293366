import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'

type StyledProps = {
    bgSection: string
    colorTitle: string
    colorSubTitle: string
    colorText: string
    bgBtn: string
    colorTextBtn: string
    size: string
    margin: string
    h1: string
  }
export const CardContainer = styled.div`
  ${() => css`
    width: 100%;
    margin: 0;
    padding: 30px 0;    
    background-color: #292A33;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    ${device.tablet} {
      margin: 0px 0 0;
    }
  `}
`
export const Title = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.color || '#37C474'};
    font-size: ${props.position || '40px'};
    text-align:  ${props.position || 'center'};
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 20px;
    margin-top: 40px;
    margin-bottom: 30px;
    
  `}
`
export const ContentBox = styled.div`
  ${() => css`
    width: 100%;
    padding: 10px 384px;
    flex-direction: colum;
    justify-content: left;
    align-items: center;
    display: flex;
    ${device.desktopL} {
      padding:10px 200px;
    }
    ${device.tablet} {
      padding: 5px;
    }
  `}
`
export const Img = styled.div`
  ${() => css`
    display: block;
    ${device.tablet} {
      display: none;
    }
  `}
`

export const ContentFaq = styled.div`
  ${() => css`
    width: 100%;    
    flex-direction: row;
    justify-content: left;
    align-items: center;
    h1  {
      color: #37C474;
      font-family: Inter;
      font-size: 24px;
      font-weight: 700;
    }
  `}
`
export const ContentFaqQuestion = styled.div`
  ${() => css`
    width: 100%;
    padding: 10px 20px; 
    flex-direction: row;
    justify-content: left;
    align-items: center;
    display: flex;
    h1  {
      color: #37C474;
      font-family: Inter;
      font-size: 18px;
      font-weight: 700;
    };
    p {
      color: #fff;
      font-family: Inter;
      font-size: 16px;
      font-weight: 400;
    };
  `}
`
export const TitleBox = styled.div`
  ${() => css`
    width: 100%;
    flex-direction: colum;
    justify-content: left;
    align-items: center;
    display: flex;
    img {
      width:42px;
      padding-left: 10px;
    }
  `}
`