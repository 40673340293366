import React, { useEffect, useState } from 'react';
import * as S from '../../page/home/styles'
import { convertAmountLunes, convertTimestamp, type_amount_lunes } from '../../utils/convert';
type BannerComponentProp = {
  data:any
}
const BannerComponent = ({...props}:BannerComponentProp) => {
    const [time, setTime] = useState({dia:0, hora:"00",min:"00",seg:"00"})
    const [value, setValue] = useState("0")
    useEffect(()=>{
     if(props.data){
        setValueActive();
        setTimes();
      }
      
    },[props.data])
    const setTimes = () =>{

      let value_ = props.data
      console.log("value_",value_)
      if(!value_ || !value_.status)
        return;
      let time_ = convertTimestamp(value_.dateRaffle.toString())
      let date_ = new Date()
      date_.setTime(time_)
      setInterval( () => {
        let dateNow = new Date()
        let timeDiff = (date_.getTime() - dateNow.getTime());
        var diffDays = Math.floor(timeDiff / (1000 * 3600 * 24));
        var diffHrs = Math.floor((timeDiff % (1000 * 3600 * 24)) / (1000 * 3600));
        var diffMins = Math.floor((timeDiff % (1000 * 3600)) / (1000 * 60));
        var diffSeg = Math.floor((timeDiff % (1000 * 60)) / 1000);

        setTime({dia:diffDays, hora:diffHrs.toString().padStart(2, '0'),min:diffMins.toString().padStart(2, '0'),seg:diffSeg.toString().padStart(2, '0')})
      },1000)
    }
    const setValueActive = () =>{
      if(props.data){
        let value_ = convertAmountLunes(props.data.totalAccumulated.toString())
        if(value_)
          setValue(type_amount_lunes(value_))  
      }
      
    }
     return <>
      <S.CardContainer>
        <S.BannerContainer>
          <S.CardBetWeb3>
            <S.Title widthTitle={"80px"}>
             Place Your Bet on Web3 with <br/><S.HighlightedText>Lotto Lunes</S.HighlightedText>
            </S.Title>
          </S.CardBetWeb3>
          <div>
            <S.TicketSymbol><img src="https://bafkreifs5zliqn7jfvj6twwvyypvvwqxoiph7holupalkksjirjpohfhle.ipfs.nftstorage.link/" alt="Ticket" />
            </S.TicketSymbol>
            <S.SequentialBetNumber>Lotto Lunes - Draw 01</S.SequentialBetNumber>
            <S.PrizeDrawCounter>{value}</S.PrizeDrawCounter>
            <S.AddAccumulated>Accumulated</S.AddAccumulated>
            {props.data?.status?(
              <S.ButtonTransparent onClick={()=>{location.href="/bet"}}>Bet now!</S.ButtonTransparent>
            ):<></>}
            
          </div>
          <div>
            <S.TextCont>Don't waste time, there are</S.TextCont>
            <S.CardTimeBet>
              <S.BoxNumExpire>
                <S.ContNum>{time.dia}</S.ContNum>
                <S.ContTex>Days</S.ContTex>
              </S.BoxNumExpire>
              <S.BoxNumExpire>
                <S.ContNum>{time.hora}</S.ContNum>
                <S.ContTex>Hours</S.ContTex>
              </S.BoxNumExpire>
              <S.BoxNumExpire>
                <S.ContNum>{time.min}</S.ContNum>
                <S.ContTex>Minutes</S.ContTex>
              </S.BoxNumExpire>
              <S.BoxNumExpire>
                <S.ContNum>{time.seg}</S.ContNum>
                <S.ContTex>Seconds</S.ContTex>
              </S.BoxNumExpire>
            </S.CardTimeBet>
          </div>
        </S.BannerContainer>
      </S.CardContainer>
    </>
  }
  export default BannerComponent;