import { getApi } from "./api"

export const saveAddress  = async (address:string) => {
    try {
        await getApi(address).post('client',{address:address})
    } catch (error) {
        console.log(error)
    }
} 
export const updatePayment  = async (address:string,raffleId:string,txId:any) => {
    try {
        await getApi(address).put(`ticket?raffleId=${raffleId}&txId=${txId}`)
    } catch (error) {
        console.log(error)
    }
} 

export const getLottoLunesAPI  = async (address:string, page:string, status:number =0 ) => {
    try {
        let data = await getApi(address).get(`ticket/getLottos?page=${page}&status=${status}`)
        return data.data;
    } catch (error) {
        console.log(error)
    }
} 

export const getMyPlayersAP1  = async (address:string,  page:string,status:number =0) => {
    try {
        console.log("address", address)
        let data = await getApi(address).get(`ticket/myPlayers?page=${page}&status=${status}`)
        return data.data;
    } catch (error) {
        console.log(error)
    }
} 

export const savePlayers  = async (address:string,  object:any) => {
    try {
        let data = await getApi(address).post(`ticket`,object)
        return data.data;
    } catch (error) {
        console.log(error)
    }
} 

export const getMyLottosAP1  = async (address:string,  page:string,status:number =0) => {
    try {
        console.log("address", address)
        let data = await getApi(address).get(`ticket/myLotto?page=${page}&status=${status}`)
        return data.data;
    } catch (error) {
        console.log(error)
    }
}