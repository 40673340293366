import React, { useEffect, useState } from 'react'
import * as S from './styles'

import ArrowBackIcon from '@mui/icons-material/ArrowBack';import WalletIcon from '@mui/icons-material/Wallet';
import { Box, CircularProgress, Grid, Icon, MenuItem, Modal, Select, Typography } from '@mui/material';
import Identicon from "@polkadot/react-identicon";
import ContractHook from '../../hooks/ContractHook';
import { convertAmountLunes, truncateText } from '../../utils/convert';


const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)'
};
const Header = ({...props}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [isDrop, setDrop] = useState(false);
  const {
    account,
    accounts,
    balanceLunes,
    error,

    connectWalletHandler,
    apiReady,
    handleOnSelect
  } = ContractHook()
  useEffect(() => {
    if (error)
      setIsOpenError(true)
  }, [error])
  const connectHandle = async () => {
    if (!apiReady) {
      return;
    }
    await connectWalletHandler()
    setIsOpen(false)

  }
  const getRoutsBets = () => {
    location.href = "/myBets"
  }
  const myAccountComponent = () => {
    return <>
    {!props.myBet?(
      <S.Button
        bgBtnHeader={"#37C474"}
        colorTextBtnHeader={"#000"}
        borderRadius={"12px"}
        onClick={getRoutsBets}
      >
        <WalletIcon htmlColor={"#000"} fontSize={'large'} /> My bets
      </S.Button>
    ):<></>}
      
      <S.ContainerAccount>
        <S.ContainerAccountBalance>{truncateText(balanceLunes.toString(), 10, 7, true)}</S.ContainerAccountBalance>
        <S.ContainerAccountAddress>{truncateText((account?.address || "").toString(), 10, 7, false)}</S.ContainerAccountAddress>
      </S.ContainerAccount>
      <S.ContainerAccountButtonImg onClick={() => setDrop(!isDrop)}>
        <S.ContainerAccountImg>
          <Identicon
            value={account?.address}
            theme='polkadot'
            size={60}
          />
        </S.ContainerAccountImg>
        <img src='/img/down-green.svg' width={"30px"} />
      </S.ContainerAccountButtonImg>
      {DropDownSelect()}
    </>
  }
  const setAccountId = () => {
    sessionStorage.setItem("accountId", account?.address || "")
    setDrop(!isDrop)
    location.reload()
  }
  const DropDownSelect = () => {
    return (
      <Modal
        open={isDrop}
        onClose={() => setDrop(!isDrop)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <S.ContainerModalWallet>
            <Select
              style={{ backgroundColor: "#fff" }}
              value={account?.address}
              label="Select Account"
              onChange={handleOnSelect}
            >
              {accounts.map((account: any) => (
                <MenuItem key={account.address} value={account.address} >
                  <Grid container alignContent={"center"} alignItems={"center"} spacing={2} style={{ backgroundColor: "#fff" }}>
                    <Grid item xs={3}>
                      <Identicon
                        value={account.address}
                        theme='polkadot'
                        size={60}
                      />
                    </Grid>
                    <Grid item xs={9}>
                      <h1>{account.meta.name}</h1>
                      <p>{account.address}</p>
                    </Grid>
                  </Grid>
                </MenuItem>
              ))}
            </Select>
            <div style={{ marginTop: "20px" }}>
              <S.ButtonRadius justify="center" onClick={() => setAccountId()}>OK</S.ButtonRadius>
            </div>
          </S.ContainerModalWallet>

        </Box>

      </Modal>
    )
  }
  const connectComponent = () => {
    return <>
      <S.Button
        bgBtnHeader={"#292A33"}
        colorTextBtnHeader={"#fff"}
        borderRadius={"12px"}
        onClick={() => setIsOpen(!isOpen)}
      >
        <WalletIcon htmlColor={"#37C474"} fontSize={'large'} />{"Connect wallet"}
      </S.Button>
      <S.ButtonMobile
        bgBtnHeader={"#292A33"}
        colorTextBtnHeader={"#fff"}
        borderRadius="12px"
        onClick={() => setIsOpen(!isOpen)}
      >
        <WalletIcon htmlColor={"#fff"} fontSize={'large'} />
      </S.ButtonMobile>
    </>
  }
  const ModalWalletConnect = () => {
    return (
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(!isOpen)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <S.ContainerModalWallet>
            <S.ContainerModalWalletTitle>
              <h1>Connect your Web3 Wallet!</h1>
              <p>Choose your favorite network and wallet to connect!</p>
            </S.ContainerModalWalletTitle>
            <S.ContainerModalWalletBody>
              <h1>Choose the walle</h1>
              <S.ContainerButton onClick={() => connectHandle()}>
                <S.ButtonRadius size="100%" color={"#fff"} backcolor={'#585860'} borderadius={"10px"} >Connect Lotto Lunes  <img src='img/lunesico.svg' style={{ marginLeft: "110px" }} /></S.ButtonRadius>
              </S.ContainerButton>
              <h1>
                Don't have the Lunes Lite extension? Download it now below!
              </h1>
              <S.ContainerButton>
                <S.ButtonRadius
                  onClick={() => window.open('https://www.talisman.xyz/download', '_blank', 'noopener')}
                  size="100%" color={"#fff"}
                  backcolor={'#585860'}
                  borderadius={"10px"}>Wallet Talisman <img src='img/talisman.png' style={{ marginLeft: "150px", width: "32px" }} />
                </S.ButtonRadius>
              </S.ContainerButton>
              <S.ContainerButton>
                <S.ButtonRadius size="100%"
                  onClick={() => window.open('https://www.subwallet.app/download.html', '_blank', 'noopener')}
                  color={"#fff"}
                  backcolor={'#585860'}
                  borderadius={"10px"}>Wallet Subwallet <img src='img/subwallet.png' style={{ marginLeft: "140px", width: "32px" }} />
                </S.ButtonRadius>
              </S.ContainerButton>
              <S.ContainerButton>
                <S.ButtonRadius
                  onClick={() => window.open('https://polkadot.js.org/extension/', '_blank', 'noopener')}
                  size="100%"
                  color={"#fff"}
                  backcolor={'#585860'}
                  borderadius={"10px"}>Wallet Polkadot extension <img src='img/poikadot.svg' style={{ marginLeft: "75px", width: "32px" }} />
                </S.ButtonRadius>
              </S.ContainerButton>
              <p>
                By connecting to Lunes, you agree to our Terms of Services.
              </p>
            </S.ContainerModalWalletBody>
          </S.ContainerModalWallet>
        </Box>
      </Modal>
    )
  }
  const ModalBet = () => {
    return (
      <Modal
        open={isOpenError}
        onClose={() => setIsOpenError(!isOpenError)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <S.ContentDetails>
            <S.ContainerError>
              <h1>Sorry!</h1>
              <p>{error}</p>
              <S.ButtonRadiusBet color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => setIsOpenError(false)}>OK</S.ButtonRadiusBet>
            </S.ContainerError>
          </S.ContentDetails>
        </Box>
      </Modal>
    )
  }
  return (
    <S.Header>
      <S.Nav>
        <S.ContentLogo onClick={() => { location.href = "/" }}>
          {props.back?(
            <>
              <ArrowBackIcon fontSize='large' color='success' /> HOME
            </>
          ):(
            <img src="img/logo.svg"/>
          )}
          
        </S.ContentLogo>
        {!apiReady ? (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        ) : (
          !account ? connectComponent() : myAccountComponent()
        )}

      </S.Nav>
      {ModalBet()}
      <ModalWalletConnect />
    </S.Header>
  )
}

export default Header
