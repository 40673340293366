import React, { useEffect, useState } from 'react';
import * as S from '../../page/home/styles'
import { convertAmountLunes, convertTimestamp, getDateView } from '../../utils/convert';
import { Box, CircularProgress, Modal } from '@mui/material';
type CalendarComponentProp = {
  data: any,
  getAll: any
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
};
const CalendarComponent = ({ ...props }: CalendarComponentProp) => {
  const [allRaffle, setAllRaffle] = useState([]);
  const [typeActive, setTypeActive] = useState(false)
  const [page, setPage] = useState(0)
  const [isOpenDetails, setIsOpenDetails] = useState(false)
  const [viewBet, setViewBet] = useState<any>()
  const [isLoading, setLoading] = useState(false)
  useEffect(() => {
    if (props.data?.data?.lottos?.length != 0) {
      setLoading(false)
      setListRaffle();
    }

  }, [props.data?.data])
  useEffect(() => {
    setLoading(true)
    GetAppPage()
  }, [typeActive, page])
  const setListRaffle = () => {

    let list_: any = [];
    for (let index = 0; index < props.data?.data.lottos?.length; index++) {
      const element = props.data.data.lottos[index];
      console.log("element", element)
      let value = convertAmountLunes(element.accumulated.toString())
      let number: any = [];
      if (element.lotto_numbers?.length > 0) {
        number.push(element.lotto_numbers[0].num1)
        number.push(element.lotto_numbers[0].num2)
        number.push(element.lotto_numbers[0].num3)
        number.push(element.lotto_numbers[0].num4)
        number.push(element.lotto_numbers[0].num5)
        number.push(element.lotto_numbers[0].num6)
      }
      let body = {
        id: element.idRiffle.toString().padStart(2, '0'),
        dateStart: element.createdAt,
        dateEnd: element.dateDraw,
        prize: value,
        price: element.price,
        numRaffle: number,
        results: element?.lotto_draws_result[0],
        status: element.status,
        statusDone: element.statusDone,
      }
      list_.push(body);
    }
    setAllRaffle(list_);
  }
  const GetAppPage = () => {
    props.getAll(page, typeActive)
  }
  const getTypeURL = (el: any) => {
    if (el.status && !el.statusDone)
      location.href = "/bet";
    else {
      setIsOpenDetails(true)
      setViewBet(el)
    }
  }
  const nextPage = () => {
    let page_ = Number(page)
    if (page_ != countTotalPage()) {
      page_ += 1;
      setPage(page_);
    }
  }
  const backPage = () => {
    let page_ = Number(page)
    if (page_ > 1) {
      page_ -= 1
      setPage(page_);
    }
  }
  const BetSelected = (number: string) => {

    return <S.NumSelected width={"24px"} Height={"24px"} size={"16px"}>{number}</S.NumSelected>
  }
  const ModalDetailsBet = () => {
    return (
      <Modal
        open={isOpenDetails}
        onClose={() => setIsOpenDetails(!isOpenDetails)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {viewBet ? (
            <S.ContentDetails>
              <img src='img/ticket-fff.svg' />
              <h1>Details Lotto</h1>
              <p>{getDateView(viewBet.dateEnd, 'yyyy-MM-dd - HH:mm:ss')}</p>
              <S.CardInfoContainer>Lotto <span>Lotto lunes  {viewBet.id.toString().padStart(2, '0')}</span></S.CardInfoContainer>
              <S.CardInfoContainer>Type <span>Lotto lunes</span></S.CardInfoContainer>
              <S.CardInfoContainer>Accumulated <span> {viewBet.prize.toString()}</span></S.CardInfoContainer>
              <S.CardInfoContainer>Ticket Price <span> {convertAmountLunes(viewBet.price.toString())}</span></S.CardInfoContainer>
              <S.TitleNumber> # Drawn numbers</S.TitleNumber>
              <S.CardBodyContainer>
                {viewBet.numRaffle.map(el => BetSelected(el.toString().padStart(2, '0')))}
              </S.CardBodyContainer>
              {viewBet.results ? (
                <>
                  <S.TitleNumber> # Drawn Prize</S.TitleNumber>
                  <S.CardHeaderTable>
                    <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"} >
                      <img src="img/ticket-star.svg" /> Point</S.TextTable>
                    <S.TextTable color={"#D4D4D6"} position={"center"} weight={"400"}>Qtd.</S.TextTable>
                    <S.TextTable color={"#37C474"} position={"right"} weight={"400"}>Value</S.TextTable>
                  </S.CardHeaderTable>
                  <S.CardHeaderTableDrw>
                    <S.CardBodyTableDraw color={"#fff"} position={"start"}>6</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#fff"} position={"center"}>{viewBet.results.quantity6}</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#37C474"} position={"right"}>{convertAmountLunes(viewBet.results.valueAward6.toString())}</S.CardBodyTableDraw>
                  </S.CardHeaderTableDrw>
                  <S.CardHeaderTableDrw>
                    <S.CardBodyTableDraw color={"#fff"} position={"start"}>5</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#fff"} position={"center"}>{viewBet.results.quantity5}</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#37C474"} position={"right"}>{convertAmountLunes(viewBet.results.valueAward6.toString())}</S.CardBodyTableDraw>
                  </S.CardHeaderTableDrw>
                  <S.CardHeaderTableDrw>
                    <S.CardBodyTableDraw color={"#fff"} position={"start"}>4</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#fff"} position={"center"}>{viewBet.results.quantity4}</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#37C474"} position={"right"}>{convertAmountLunes(viewBet.results.valueAward4.toString())}</S.CardBodyTableDraw>
                  </S.CardHeaderTableDrw>
                  <S.CardHeaderTableDrw>
                    <S.CardBodyTableDraw color={"#fff"} position={"start"}>3</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#fff"} position={"center"}>{viewBet.results.quantity3}</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#37C474"} position={"right"}>{convertAmountLunes(viewBet.results.valueAward3.toString())}</S.CardBodyTableDraw>
                  </S.CardHeaderTableDrw>
                  <S.CardHeaderTableDrw>
                    <S.CardBodyTableDraw color={"#fff"} position={"start"}>2</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#fff"} position={"center"}>{viewBet.results.quantity2}</S.CardBodyTableDraw>
                    <S.CardBodyTableDraw color={"#37C474"} position={"right"}>{convertAmountLunes(viewBet.results.valueAward2.toString())}</S.CardBodyTableDraw>
                  </S.CardHeaderTableDrw>                 
                </>
              ) : <></>}
              <S.ButtonRadiusBet color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={""} onClick={() => setIsOpenDetails(false)}>
                OK
              </S.ButtonRadiusBet>
            </S.ContentDetails>
          ) : (
            <CircularProgress />
          )}

        </Box>
      </Modal>
    )
  }
  const getTypeDescription = (el: any) => {
    let description = ""
    if (el.status && !el.statusDone)
      description = "Buy Ticket"
    else if (!el.status && el.statusDone)
      description = "drawn numbers"
    else
      description = "Soon"

    return description
  }
  const ItemComponent = () => {
    return allRaffle.map((el: any) => (
      <S.CardBodyTable key={el.id}>
        <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"} >
          <img src="img/ticket-star.svg" /> Lotto {el.id}</S.TextTable>
        <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>{getDateView(el.dateStart, "yyyy-MM-dd")}</S.TextTable>
        <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>{getDateView(el.dateEnd, "yyyy-MM-dd")}</S.TextTable>
        <S.TextTable color={"#37C474"} position={"start"} weight={"400"}>{el.prize}</S.TextTable>
        <S.TextTable color={el.status ? "#37C474" : "#F8B304"} position={"start"} weight={"400"}>{el.status ? "In Progress" : "Waiting for the draw"}</S.TextTable>
        <S.TextTable color={"#D4D4D6"} position={"start"} weight={"400"}>
          <S.ButtonRadius color={"#fff"} backcolor={'transparent'} borderadius={"12px"} bordercolor={"1px solid #37C474"} onClick={() => getTypeURL(el)}>
            {getTypeDescription(el)}
          </S.ButtonRadius>
        </S.TextTable>
      </S.CardBodyTable>
    ))
  }
  const countTotalPage = () => {
    if (!props.data)
      return 0;
    let t = Math.ceil(Number(props.data?.data?._count) / 50)
    if (t == 0)
      t = 1
    return t
  }
  if (allRaffle.length == 0 || !allRaffle)
    return <>
      <S.Container>
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      </S.Container>
    </>
  return <>
    <S.CardContainer>
      <S.ContentTitle>
        <S.TitleHome>Calendar</S.TitleHome>
        <S.SubTitleHome>
          The winning numbers, the latest prizes, and the upcoming draws are all here! See the latest lucky ones who aligned their stars and won big. Your name could be next!
        </S.SubTitleHome>
      </S.ContentTitle>
      <S.ContentDraws>
        <S.TitleHome color={"#fff"} position={"start"}>Draws</S.TitleHome>
        <S.ContentRow>
          <S.ButtonRadius backcolor={!typeActive ? null : '#292A33'} color={!typeActive ? null : "#BCBCBC"} onClick={() => setTypeActive(false)}>Upcoming Draws</S.ButtonRadius>
          <S.ButtonRadius backcolor={typeActive ? null : '#292A33'} color={typeActive ? null : "#BCBCBC"} onClick={() => setTypeActive(true)}>Previous bets</S.ButtonRadius>
        </S.ContentRow>
        <S.CardHeaderTable>
          <S.TextTable color={"#fff"} position={"start"}>Draw Numbe</S.TextTable>
          <S.TextTable color={"#fff"} position={"start"}>Start Date</S.TextTable>
          <S.TextTable color={"#fff"} position={"start"}>End Date</S.TextTable>
          <S.TextTable color={"#fff"} position={"start"}>Prize</S.TextTable>
          <S.TextTable color={"#fff"} position={"start"}>Status</S.TextTable>
          <S.TextTable color={"#fff"} position={"start"}>Action</S.TextTable>
        </S.CardHeaderTable>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <ItemComponent />
        )}

        <S.CardFooterTable>
          <S.TextFooterTable color={"#fff"} position={"start"}> Page {page + 1} of {countTotalPage()}</S.TextFooterTable>
          <S.ButtonFooterRadius backcolor={"#000"} onClick={() => backPage()}>
            <img src="img/back.png" />
          </S.ButtonFooterRadius>
          <S.ButtonFooterRadius backcolor={"#000"} onClick={() => nextPage()}>
            <img src="img/next.png" />
          </S.ButtonFooterRadius>
        </S.CardFooterTable>
      </S.ContentDraws>
    </S.CardContainer>
    {ModalDetailsBet()}

  </>
}

export default CalendarComponent