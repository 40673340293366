import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'
import { Button } from '../home/styles'

type StyledProps = {
    bgSection: string
    colorTitle: string
    colorSubTitle: string
    colorText: string
    bgBtn: string
    colorTextBtn: string
    size: string
    margin: string
    h1: string
    position:string,
    padding:string
    width: string
    Height:string
  }
export const Container = styled.section`
  ${() => css`
    width: 70%;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    ${device.tablet} {
      height: auto;
      padding: 10px 24px;
    }
    ${device.laptopL} {
      width: 95%;
      margin: 5px auto;
    }
  `}
`
export const CardContainer = styled.div`
  ${() => css`
    width: 100%;
    padding: 0px 0; 
    flex-direction: row;
    padding: 5px;
    justify-content: center;
    align-items: center;   
    background-color: #171821;
    color: #FFFFFF;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 12px;
  `}
`
export const Title = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:${props.width || '100%'};
    color: ${props.color || '#37C474'};
    font-size: ${props.size || '24px'};
    text-align:  ${props.position || 'center'};
    padding: ${props.padding || '0px'};
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 20px;    
    img {
      display: flex;  
      float:left;
      margin-right: 10px;  
      justify-content: center;
    }
  `}
`
export const CardBetContainer = styled.div`
  ${() => css`
    width: 100%;
    padding: 0px 0; 
    display: flex;  
    flex-direction: colum;
    padding: 5px;
    justify-content: center;
    align-items: start;   
    color: #FFFFFF;
    ${device.tablet} {
      margin: 0px 0 0;
    }
  `}
`
export const CardBetBodyContainer = styled.div`
  ${() => css`
    width: 100%;
    max-width: 500px;
    padding: 0px 0; 
    flex-direction: row;
    padding: 30px;    
    justify-content: flex-start;
    align-items: center;   
    color: #FFFFFF;
    font-size: 24px;
    text-align:  center;
    font-weight: 700;
    flex-wrap: wrap;  
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 12px;
  `}
`
export const ButtonContainer = styled.div`
  ${() => css`
    width: 100%;
    padding: 0px 0; 
    display: flex;  
    Height:56px;
    flex-direction: row;
    padding: 5px;  
    background-color: #292A33;
    cursor:pointer;
    color: #FFFFFF;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 30px;
  `}
`
export const ButtonActiveContainer = styled.div`
  ${() => css`
    width: 50%;
    padding: 0px 0; 
    padding: 5px;
    display: flex;  
    justify-content: center;
    align-items: center;   
    background-color: #37C474;
    color: #000;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 30px;
  `}
`
export const ButtonDisableContainer = styled.div`
  ${() => css`
    width: 50%;
    padding: 0px 0; 
    display: flex;  
    padding: 5px;
    justify-content: center;
    align-items: center;   
    color: #fff;
    ${device.tablet} {
      margin: 0px 0 0;
    }
  `}
`
export const ButtonBet = styled(Button)<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:${props.width || '30px'};
    Height:${props.Height || '35px'};
    display: flex;  
    justify-content: center;
    align-items: center;   
    margin: 5px;
    color: ${props.colorTextBtn || '#858585'};
    font-size: 12px;
    text-align:  center;
    background-color: ${props.color || '#FFFFFF'};
    border-radius: 5px;
  `}
`
export const CardBodyContainer = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
	  flex-flow: row wrap;
    padding: 10px; 
    flex-direction: colum;
    justify-content: center;
    align-items: center;   
    color: #FFFFFF;
    text-align:  center;
    background-color: #292A33;
    margin: 0px 0 10px;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 12px;
  `}
`
export const NumSelected = styled.div<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:${props.width || '60px'};
    Height:${props.Height || '55px'};    
    display: flex;  
    justify-content: center;
    align-items: center;   
    background-color: #fff;
    color: #000;
    font-size: ${props.size || '24px'};
    margin: 15px 5px 10px;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 8px;
  `}
`
export const CardInfoContainer = styled.div`
  ${() => css`
    width: 100%;
    padding: 0px 0; 
    flex-direction: row;
    padding: 5px;
    justify-content: center;
    align-items: center; 
    text-align:  left;  
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    span {
      font-weight: 400;
      float:right;
    }
  `}
`
export const HrBet = styled.div`
  ${() => css`
    width: 100%;
    margin-top: 20px;
    padding: 0.2px 0; 
    background-color: #9E9E9E;
  `}
`
export const ButtonRadius = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    max-width:${props.size || '142px'};
    width: 100%;
    height: 52px;
    margin: auto;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;
    border-radius:${props.borderadius || '100px'};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.backcolor || '#37C474'};
    color: ${props.color || '#000'};
    border:${props.bordercolor || '#37C474'};
  `}
`

export const ContainerButton = styled.div`
  ${() => css`
    width: 100%;
    margin: 0px;
    padding: 5px;
    justify-content: space-around;
  `}
`
export const ContainerLink = styled.div`
  ${() => css`
    width: 100%;
    margin-top: 30px;
    padding: 5px;
    text-align:  right;  
    color: #37C474;
    font-weight: 700;
    font-size: 18px;
    cursor:pointer;
    img { 
      float:right;
      margin-left: 20px;
    }
  `}
`
export const ContainerInfoCommunity = styled.div`
  ${() => css`
    width: 100%;
    margin-top: 100px;
    padding: 5px;
    justify-content: space-around;
  `}
`
export const ContainerSuccess = styled.div`
  ${() => css`
    width: 500px;
    padding: 10px;
    text-align: center;
    h1{
      color: #37C474; 
      font-weight: bold;
      margin: 20px;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin: 30px;
    }
    justify-content: space-around;
  `}
`
export const ContainerError = styled.div`
  ${() => css`
    width: 500px;
    padding: 10px;
    text-align: center;
    h1{
      color: #F8B304; 
      font-weight: bold;
      margin: 20px;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin: 30px;
    }
    justify-content: space-around;
  `}
`
export const ContentDetails = styled.div`
  ${() => css`
    width: 500px;
    padding: 20px;
    text-align: left;
    margin: auto;
    background-color: #171821;
    border-radius: 12px;
  
  `}
`