import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'

type StyledProps = {
    bgSection: string
    colorTitle: string
    colorSubTitle: string
    colorText: string
    bgBtn: string
    colorTextBtn: string
    size: string
    margin: string
    h1: string
  }
export const CardContainer = styled.div`
  ${() => css`
    width: 100%;
    margin: 0;
    padding: 40px 0;    
    background-color: #171821;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    bottom: 0px;
    
  `}
`
export const Title = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.color || '#7899F5'};
    font-size: 26px;
    text-align:  ${props.position || 'center'};;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 40px;
    margin-top: 30px;
  `}
`

export const SubTitle = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.color || '#fff'};
    font-size: 16px;
    text-align: ${props.position || 'center'};
    font-weight: normal;
    padding-bottom: 16px;
    padding-top: 28px;
    span {
      font-weight: bold;
    }
    `}
`