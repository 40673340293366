import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'
import { Button } from '../../page/home/styles';

type StyledProps = {
    bgSection: string
    colorTitle: string
    colorSubTitle: string
    colorText: string
    bgBtn: string
    colorTextBtn: string
    size: string
    margin: string
    h1: string
  }
export const CardContainer = styled.div`
  ${() => css`
    width: 100%;
    margin-top: 80px; 
    margin-bottom: 160px; 
    padding: 0px 0;    
    background-color: transparent;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    ${device.tablet} {
      margin: 0px 0 0;
    }   
  `}
`
export const Title = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.color || '#37C474'};
    font-size: 28px;
    text-align:  ${props.position || 'center'};;
    font-weight: 700;
    padding-bottom: 36px;
    padding-top: 20px;
  `}
`
export const SubTitle = styled.h1`
  ${() => css`
    color: #fff;
    font-size: 18px;
    font-weight: 50;
    text-align: center;
    width: 495px;
    margin:auto;
    padding-bottom: 30px;
    ${device.tablet} {
      width: 395px;
    }
  `}
`
export const CardTitle = styled.div<Partial<StyledProps>>`
  ${() => css`
  margin-left: 0px;
  color: #F8B304;
  font-family: Inter;
  font-size: 24px;
  font-weight: normal;
  line-height: 100%; /* 36px */
 
`}
`	
export const ContentBox = styled.div`
  ${() => css`
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    
  `}
`
export const BoxWithFoot = styled.div`
  ${() => css`
    width: 430px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 26px 26px 26px 26px;
    p {
        text-align: left;   
        font-family: Inter;
        font-size: 12px;  
    }
    ${device.desktopL} {
      margin: auto;
    
  `}
`
export const BoxWithIcon = styled.div`
  ${() => css`
    display: flex;
    height: 359px;
    height: 359px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #171821;    
  `}
`
export const IconBox = styled.div`
${() => css`
    display: flex;
    Width: 243px;
    height: 243px;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    background-color: #292A33;   
    
    img {
        margin:auto;        
    }
`}
`
export const DescTex = styled.h2`
  ${() => css`
    color: #fff;
    font-size: 14px;
    font-weight: 50;
    text-align: justify;
    margin-top: 20px;
    width: 418px;
    ${device.tablet} {
      width: 395px;
    }
  `}
`
export const HighlightedText = styled.span`
  ${({ ...props }) => css`
  color: #37C474;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  `}
`