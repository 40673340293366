import device from '../../components/devices/devices'
import styled, { css } from 'styled-components'

type StyledProps = {
  bgSection: string
  colorTitle: string
  colorSubTitle: string
  colorText: string
  bgBtn: string
  colorTextBtn: string
  size: string
  margin: string
  h1: string
  isLink : boolean
}

export const Container = styled.section`
  ${() => css`
    width: 70%;
    margin: 20px auto;
    padding: 30px;
    align-items: center;
    justify-content: center;
    ${device.tablet} {
      height: auto;
      padding: 10px 24px;      
    }
    ${device.laptopL} {
      width: 95%;
      margin: 5px auto;
    }
  `}
`
export const Content = styled.div`
  ${({ }) => css`
    width: 100%;
    max-width: 820px;
    margin: auto 0;
    padding: 48px 0;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    ${device.tablet} {
      padding: 0;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  `}
`
export const Wrapper = styled.div`
  ${({ }) => css`
    width: 100%;
    max-width: 410px;
    min-height: 275px;
    max-height: 660px;
    margin-left: 24px;
    text-wrap: pretty;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    > img {
      margin-top: 105px;
      max-height: 700px;
      ${device.tablet} {
        margin-top: 24px;
      }
    }
    ${device.laptop} {
      margin-top: 24px;
    }
    ${device.tablet} {
      max-height: 100%;
      margin: 0 16px 24px;
      justify-content: center;
    }
  `}
`

/*****Título principal H1 banner*******/
export const Title = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.colorTitle || '#fcc725'};
    width: ${props.widthTitle || '350px'}
    font-size: 30px;
    font-weight: 800;
    text-shadow: 0px 4px 24px #000;
    font-family: Inter;
    line-height: 150%; /* 36px */
    padding-right: 14px;
  `}
`
/*******Título principal verde H1 banner********/
export const HighlightedText = styled.span`
  ${({ ...props }) => css`
  color: #37C474;
  font-family: Inter;
  font-size: 32px;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  `}
`
/*****Título simbolo ticket banner*******/
export const TicketSymbol = styled.div`
  ${({ ...props }) => css`
  display: flex;
  margin: 32px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  `}
  `

/*****Contador Série da aposta banner*******/
export const SequentialBetNumber = styled.h2<Partial<StyledProps>>`
  ${({ ...props }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fcc725;
  font-size: 14px;
  font-weight: 800;
  text-shadow: 0px 4px 24px #000;
  font-family: Inter;
  gap: 10px;
  margin: 0 auto;
  `}
`
/*****Contador valor do prêmio que será sorteado banner*******/
export const PrizeDrawCounter = styled.h2<Partial<StyledProps>>`
  ${({ ...props }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props.colorTitle || '#fcc725'};
  text-align: center;
  font-family: Inter;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  `}
`

/*****Contador Série da aposta banner*******/
export const AddAccumulated = styled.h2<Partial<StyledProps>>`
  ${({ ...props }) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #37C474;
  font-family: Inter;
  font-size: 32px;
  font-weight: 700;
  text-shadow: 0px 4px 24px #000;
  `}
`

/*****Título H1 lado direito banner*******/
export const TextCont = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    display: flex;
    color: ${props.colorTitle || '#fcc725'};
    align-content: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 800;
    text-shadow: 0px 4px 24px #000;
    font-family: Inter;
    line-height: 150%; /* 36px */
  `}
`

export const SubTitle = styled.h3<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.colorSubTitle || '#fcc725'};
    font-size: 16px;
    font-weight: 700;
    padding: 16px 0;
  `}
`

export const Paragraph = styled.p<Partial<StyledProps>>`
  ${({ ...props }) => css`
    margin: ${props.margin};
    color: ${props.colorText || '#000'};
    font-size: 16px;
    font-weight: 400;
    display: flex;
    align-items: end;
    img {
      margin-right: 4px;
    }
  `}
`

export const Span = styled.span<Partial<StyledProps>>`
  ${({ ...props }) => css`
    margin: ${props.margin};
    color: ${props.colorText || '#000'};
    font-size: ${props.size || '24px'};
    font-weight: 700;
    display: flex;
    align-items: end;
    text-align: center;
    p {
      margin-left: 4px;
    }
  `}
`

export const Button = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    max-width: 409px;
    width: 100%;
    height: 54px;
    margin: 24px 0 16px;
    padding: 0px 16px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.bgBtn || '#fcc725'};
    color: ${props.colorTextBtn || '#000'};
  `}
`

export const CardContainer = styled.div`
  ${() => css`
    width: 100%;
    padding: 30px 0;    
    background-color: #171821;
    color: #FFFFFF;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 12px;
  `}
`
export const BannerContainer = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    align-items: flex-start;
    background: url(img/Banner-Desktop.svg);
    background-repeat: no-repeat;
    height: 394px;
    background-size: cover;
    border-radius: 12px;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    ${device.tablet} {
      background: url(img/Banner-Mobile.svg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  `}
`
export const BannerSpan = styled.span`
  ${() => css`
    display: flex;
    margin: 0;
    color: #000;
    font-size: 24px};
    font-weight: 700;
    display: flex;
    align-items: end;
    text-align: center;
    p {
      margin-left: 4px;
    }
  `}
`

export const ButtonTransparent = styled.button<StyledProps>`
  ${() => css`
    display: flex;
    width: 244px;
    height: 64px;
    padding: 12px 14px;
    margin: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: 2px solid;
    border-radius: 12px;
    border-color: #37C474;
    background-color: #0C0C0A;
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 550;
    line-height: normal;
  `}
`
export const CardBetWeb3 = styled.div<Partial<StyledProps>>`
  ${() => css`
  display: flex;
  margin-left: 22px;
  justify-content: center;
  align-items: center;
  color: #F8B304;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%; /* 36px */
  ${device.tablet} {
    display: none;  
  }
`}
`	
/*****Cards Time Bet*****/
export const CardTimeBet= styled.div<Partial<StyledProps>>`
  ${() => css`
  display: flex;  
  flex-direction: row;
  padding: 18px;
  justify-content: center;
  align-items: center;
  ${device.tablet} {
    display: none;  
  }
`}
`	
/*****Box contagem regreciva*****/
export const BoxNumExpire = styled.div`
  ${() => css`
  display: flex;
  width: 88px;
  height: 87px;
  flex-direction: column;
  margin: -18px 8px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #F8B304; 
  
  & > *:not(:first-child) { 
    margin-top: -10px; 
  }
  ${device.tablet} {
    display: none;  
  }
`}
`	
export const ContNum = styled.span`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 48px;
    color: #000;
  `}
`;

export const ContTex = styled.span`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: #000;
  `}
`;

export const TitleHome = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    color: ${props.color || '#37C474'};
    font-size: 28px;
    text-align:  ${props.position || 'center'};;
    font-weight: 700;
    padding-bottom: 10px;
    padding-top: 20px;
  `}
`
export const SubTitleHome = styled.h1`
  ${() => css`
    display: flex;
    color: #fff;
    font-size: 18px;
    font-weight: 50;
    text-align: center;
    width: 495px;
    margin:auto;
    ${device.tablet} {
      width: 95%;      
      margin: 10px 0px 0px;
    }
  `}
`
export const ContentTitle = styled.div`
  ${() => css`
    width: 100%;
    flex-direction: row;
    margin: 34px 10px 10px;
    text-align: center;
    justify-content: center;
    align-items: center;
   
  `}
`

export const ContentDraws = styled.div`
  ${() => css`
    width: 100%;
    flex-direction: row;
    margin: 34px 30px 10px;
  `}
`
export const ButtonRadius = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    max-width: 142px;
    width: 100%;
    height: 52px;
    margin: 14px 5px 16px;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;    
    border-radius:${props.borderadius || '100px'};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.backcolor || '#37C474'};
    color: ${props.color || '#000'};
    border:${props.bordercolor || ''};
  `}
`
export const ContentRow = styled.div`
  ${() => css`
    width: 100%;
    display: flex;
    flex-direction: row;
  `}
`
export const CardHeaderTable = styled.div`
  ${({ ...props }) => css`
    width: 95%;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    background-color: #292A33;
    color: #FFFFFF;
   
    ${device.tablet} {
      display: none;  
    }
  `}
`
export const CardBodyTable = styled.div<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width: 95%;
    height:100px;
    margin: 14px 0px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    flex-direction: row;
    background-color: ${props.backcolor || '#292A33'};
    color: #FFFFFF;
    border:${props.bordercolor || ''};
    ${device.tablet} {
      display: none;  
    }
  `}
`

export const TextTable = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:350px;
    flex-direction: row;
    display: flex;
    padding: 10px;
    color: ${props.color || '#37C474'};
    margin: 0px 10px 0px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-align:  ${props.position || 'center'};
    font-weight: ${props.weight || '700'};
    img {
      margin-right: 10px;
    }
  `}
`

export const TextTable1 = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:350px;
    flex-direction: row;
    display: flex;
    padding: 10px;
    color: ${props.color || '#37C474'};
    margin: 0px 10px 0px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-align:  ${props.position || 'center'};
    font-weight: 700;
  `}
`
export const TextTable2 = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:350px;
    flex-direction: row;
    display: flex;
    padding: 10px;
    color: ${props.color || '#37C474'};
    margin: 0px 10px 0px;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    text-align:  ${props.position || 'center'};
    font-weight: 400;
  `}
`

export const CardFooterTable = styled.div`
  ${() => css`
    width: 95%;
    display: flex;
    margin: 14px 0px 0;
    border-radius: 10px;
    flex-direction: row;
    background-color: #292A33;
    font-size: 16px;
    justify-content: right;
    align-items: right;
    color: #FFFFFF;
    ${device.tablet} {
      width:85%;
    }
  `}
`

export const TextFooterTable = styled.h1<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:350px;
    flex-direction: row;
    display: flex;
    padding: 10px;
    color: ${props.color || '#37C474'};    
    font-size: 16px;
    justify-content: right;
    align-items: center;
    text-align:  ${props.position || 'center'};;
    font-weight: 500;

  `}
`

export const ButtonFooterRadius = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    max-width: 42px;
    width: 100%;
    margin: 14px 5px 16px;
    padding: 10px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;
    border-radius:${props.borderadius || '12px'};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.backcolor || '#37C474'};
    color: ${props.color || '#000'};
    border:${props.bordercolor || ''};
  `}
`

export const CardMobileHerderTable = styled.div`
  ${() => css`
    width: 95%;
    display: flex;
    border-radius: 10px;
    flex-direction: row;
    background-color: #292A33;
    color: #FFFFFF;
    ${device.desktop} {
      display: none;  
    }
    ${device.tablet} {
      background-color: #292A33;
      display: inline;  
    }
  `}
`
export const ContentWinner = styled.div`
  ${() => css`
    display: flex;
    width: 95%;
    margin: auto;
    padding: 10px;  
    text-align: left;
    justify-content: left;
    align-items: center;
    background-color: #7899F5;
    border-radius: 10px;
    font-size: 16px;
    font-weight: 700;
    img {      
      float:left;
      margin-right: 10px;
    }
  `}
`
export const ContentWinnerButton = styled.div`
  ${() => css`
    margin: auto;
  `}
`

export const ContentDetails = styled.div`
  ${() => css`
    width: 600px;
    padding: 20px;
    text-align: left;
    margin: auto;
    background-color: #171821;
    border-radius: 12px;
    h1{
      margin-top: 10px;
      color: #37C474; 
      font-weight: bold;
      font-size: 20px;
    }
    p{
      color: #FFFFFF;
      font-size :18px ;
      margin-bottom: 20px;
    }
  `}
`
export const CardInfoContainer = styled.div<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width: 100%;
    padding: 0px 0; 
    flex-direction: row;
    padding: 5px;
    justify-content: center;
    align-items: center; 
    text-align:  left;  
    color: #37C474;
    font-weight: 700;
    font-size: 18px;
    text-decoration: ${props.isLink?"dotted": ''};;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    span {
      color: #fff;
      font-weight: 400;
      float:right;
    }
  `}
`
export const NumSelected = styled.div<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width:${props.width || '60px'};
    Height:${props.Height || '55px'};    
    display: flex;  
    justify-content: center;
    align-items: center;   
    background-color: ${props.backcolor || '#fff'};
    color: #000;
    font-size: ${props.size || '24px'};
    margin: 15px 5px 10px;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 8px;
  `}
`
export const CardBodyContainer = styled.div`
  ${({ ...props }) => css`
    width: 100%;
    display: flex;
	  flex-flow: row wrap;
    padding: 10px; 
    flex-direction: colum;
    justify-content: center;
    align-items: center;   
    color: #FFFFFF;
    text-align:  center;
    background-color:${props.backcolor || '#292A33'};
    margin: 0px 0 10px;
    ${device.tablet} {
      margin: 0px 0 0;
    }
    border-radius: 12px;
  `}
`

export const TitleNumber = styled.h1<Partial<StyledProps>>`
  ${() => css`
    color: #FFFFFF;
    width:100%;
    font-size: 30px;
    font-weight: 800;
    text-shadow: 0px 4px 24px #000;
    font-family: Inter;
    line-height: 150%; /* 36px */
    padding-right: 14px;
    text-align: center;
  `}
`
export const ButtonRadiusBet = styled.button<Partial<StyledProps>>`
  ${({ ...props }) => css`
    width: 100%;
    height: 52px;
    margin: 14px 5px 16px;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;
    gap: 8px;
    display: flex;    
    border-radius:${props.borderadius || '100px'};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    background: ${props.backcolor || '#37C474'};
    color: ${props.color || '#000'};
    border:${props.bordercolor || ''};
  `}
`

export const ContainerSuccess = styled.div`
  ${() => css`
    padding: 10px;
    text-align: center;
    h1{
      color: #37C474; 
      font-weight: bold;
      margin: 20px;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin: 30px;
    }
    justify-content: space-around;
  `}
`
export const ContainerError = styled.div`
  ${() => css`
    padding: 10px;
    text-align: center;
    h1{
      color: #F8B304; 
      font-weight: bold;
      margin: 20px;
    }
    p{
      font-size: 14px;
      color: #FFFFFF; 
      margin: 30px;
    }
    justify-content: space-around;
  `}
`